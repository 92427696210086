import React from 'react'

const Header = () => {
  return (
    <header>
    <h1>the reverse room</h1>  
    </header>
  )
}

export default Header
