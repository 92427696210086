import React from 'react'

const NotFound = () => {
  return (
    <div className='notfound'>
        <h2 style={{color:'white'}}>Page Not Found</h2>
      
    </div>
  )
}

export default NotFound
